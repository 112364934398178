@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Open Sans',sans-serif;
  /*font-family: 'M PLUS 1p', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-image: url('./bg.png');
  background-color: #222222;
  background-size: 200% 180%;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: gradientTransform;
  background-repeat: no-repeat;
}

video_fondo
{
 margin:0px;
 padding:0px;
}
.video_fondo #video_wrapper video
{
 position: fixed;
 top: 50%; left: 50%;
 z-index: 1;
 min-width: 100%;
 min-height: 100%;
 width: auto;
 height: auto;
 transform: translate(-50%, -50%);
}
#wrapper video_fondo
{
 position:relative;
 text-align:center;
 margin:0 auto;
 padding:0px;
 width:995px;
 z-index:2;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
